import * as React from "react"
import $ from 'jquery'

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Component} from "react";

export class SubscriptionAgreement extends Component {
    componentDidMount() {
        $(document).ready(function () {
            $('a[href*=\\#]').bind('click', function (e) {
                var target = $(this).attr("href");
                $('html, body').stop().animate({
                    scrollTop: $(target).offset().top + 10
                }, 600, function () {
                    window.location.hash = target;
                });
                return false;
            });
        });

        $(window).scroll(function () {
            var scrollDistance = $(window).scrollTop();

            $('.content-section').each(function (i) {
                if ($(this).position().top - 10 <= scrollDistance) {
                    $('.nav-helper a.active-side').removeClass('active-side');
                    $('.nav-helper a').eq(i).addClass('active-side');
                }
            });
        }).scroll();


    }

    render() {
        return (
            <Layout>
                <div className="content">

                    <div className="content-body">
                        <div className="content-section" id="terms-and-condition">
                            <h1>Subscription Agreement</h1>
                            <br/>
                            <p>These terms and conditions apply to the provision of the
                                service registered through the website at URL
                                https://studio.thehyperstack.com, and mobile apps, third party sites, or anywhere else
                                the services may be offered (collectively, “Services”).
                                If the Services were ordered through an order form theses terms are incorporated in the
                                order form and to the extent there are any conflicts
                                between these terms and the order form, these terms will apply. These terms are
                                incorporated into the Order Form and together, along with any
                                addendums identified on the Order Form, form the “Agreement.”. </p>
                        </div>
                        <div className="content-section" id="services">
                            <h2>1. Services</h2>

                            <p>Subject to the terms of this Agreement, the Provider grants
                                to the Customer a
                                non-exclusive right to use the Services according to the website or other order form
                                used to purchase the Services.
                                The Provider offers a variety of paid and free accounts on its website. These accounts
                                will be referred to as Paid and Free accounts,
                                respectively. In a hosted environment, together with any other products and services
                                defined in the Order Form,
                                the Provider shall create an Account for Customers and clients who use the service
                                through the Customer, the Services purchased
                                may include a platform that allows online learning providers, schools, and universities
                                to sign-up and issue credentials (“Credential Cloud”)
                                and software that allows the customer to design smart digital certificates, badges,
                                diplomas, and credentials, as well as customer login information for that Account.</p>
                        </div>
                        <div className="content-section" id="customer-responsibility">
                            <h2>2. Customer Responsibility</h2>

                            <p>The customer agrees to:</p>

                            <ul className="page-list">
                                <li>Remain responsible for all activity under all accounts it creates for other
                                    people;
                                </li>
                                <li>Maintain the confidentiality and security of passwords and abide by any access
                                    protocols or credential requirements set by Provider;
                                </li>
                                <li>Use commercially reasonable efforts to prevent unauthorized access to or use of the
                                    Service;
                                </li>
                                <li>Promptly notify Provider of any such unauthorized access or use of which it
                                    learns;
                                </li>
                                <li>Reasonably cooperate with respect to implementation, access, and support; and</li>
                                <li>Ensure that a current email address is associated with each User's account</li>
                            </ul>
                        </div>
                        <div className="content-section" id="customer-restrictions">
                            <h2>3. Customer Restrictions</h2>

                            <p>The rights granted by the Provider to the Customer under
                                Section 1 is subject to the following limitations regarding
                                the Services.</p>

                            <ul className="page-list">
                                <li>The Services may only be used by the named users that have been granted access
                                    credentials to the Customer Account;
                                </li>
                                <li>The credentials stored in the cloud can only be altered by the Customer and the
                                    Provider;
                                </li>
                                <li>The Customer must not sub-license its rights granted under this Agreement;</li>
                                <li>The Customer must not permit any unauthorized person to access or use the
                                    Services;
                                </li>
                                <li>The Customer must not make any alterations to the Services;</li>
                                <li>The Customer must not use the Services provided by the Provider in any way that
                                    causes, or may cause, damages to the Services or Platform or impairment of
                                    the availability or accessibility of the Services, and
                                </li>
                                <li>The Customer must not use the Services in any way or in connection with any
                                    unlawful, illegal, fraudulent,
                                    or harmful purpose or activity.
                                </li>
                            </ul>
                        </div>
                        <div className="content-section" id="customer-data">
                            <h2>4. Customer Data</h2>

                            <p>Customer agrees that data derived from Provider's provision
                                of the Service or Customer's
                                use of the Service (“Usage Data”) may be used by the Provider as listed on the
                                Provider's
                                Privacy Policy page (https://thehyperstack.com/privacy-policy/). </p>
                        </div>
                        <div className="content-section" id="confidentiality">
                            <h2>5. Confidentiality</h2>

                            <p>The Parties acknowledge that the terms of this Agreement and
                                any oral or
                                written information exchanged between the Parties in connection with the preparation and
                                performance of
                                this Agreement are confidential. Accordingly, each party shall maintain the
                                confidentiality of all such information.
                                Without obtaining the written consent of the other party, Customer and Provider shall
                                not disclose any relevant confidential
                                information to any third parties, except for the information that: (1) is or will be in
                                the public domain; (2) is required to be
                                disclosed pursuant to the applicable laws or regulations. This Section shall survive the
                                termination of this Agreement for any reason.</p>
                        </div>
                        <div className="content-section" id="representations-and-warranties">
                            <h2>6. Representations and Warranties</h2>

                            <p><span className='list-numbering'>6.1</span> Each party
                                represents that (1) it has the power and authority to validly enter into this Agreement,
                                (2) this Agreement has been duly and validly authorized, executed, and delivered, (3)
                                the execution and delivery of this Agreement does not violate or conflict with any other
                                agreement, license, or obligation of both parties, (4) no illegal or improper bribes,
                                kickbacks, payments, gifts, or things of value have been offered or received from or on
                                behalf of any employees or agents of the other party in connection with this Agreement,
                                and (5) it is financially solvent and has the ability to perform its obligations
                                hereunder.</p>

                            <p><span className='list-numbering'>6.2</span> THE PROVIDER
                                HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, OTHER THAN THE WARRANTY STATED IN SECTION
                                12.1, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
                                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                                NON-INFRINGEMENT OF THIRD-PARTY RIGHTS WITH RESPECT TO ANY SERVICE PROVIDED BY PROVIDER.
                                NOTHING IN THIS SECTION SHALL MODIFY THE PROVIDER'S OBLIGATION TO INDEMNIFY CUSTOMERS AS
                                REQUIRED BY SECTION 14.2.1 OF THIS AGREEMENT (“INDEMNIFICATION”).</p>
                        </div>
                        <div className="content-section" id="support-and-maintenance">
                            <h2>7. Support and Maintenance</h2>

                            <p><span className='list-numbering'>7.1</span>The Provider will
                                use commercially reasonable efforts to ensure the Customer availability of the Services
                                in accordance with industry standards and provide reasonable assistance during the Term
                                (“Support Services”). These Support Services do not apply to any scheduled outages,
                                standard maintenance windows, force majeure, and outages that result from any technology
                                issue not originating from the Provider. </p>

                            <p><span className='list-numbering'>7.2</span>The Provider
                                reserves the right to temporarily suspend access to the Services for operational
                                purposes, including, but not limited to, maintenance, repairs, or installation of
                                upgrades, and will strive to provide no less than two business days’ notice prior to any
                                such suspension. Such notice shall be provided to the Customer in advance by email.
                                Further, the Provider shall strive to confine planned operational suspensions to
                                minimize disruption to the Customer.</p>

                            <p><span className='list-numbering'>7.3</span>The Support
                                Services will be tailored to reflect the product the Customer purchased. The Support
                                Services will be provided following the standard of skill and care reasonably expected
                                from a leading service provider in Provider’s industry. The Support Services will be
                                made available to the Customer as outlined in their product. The Provider may suspend
                                Support Services at the end of the Term.</p>
                        </div>
                        <div className="content-section" id="blockchain-security">
                            <h2>8. Blockchain Security</h2>

                            <p><span className='list-numbering'>8.1</span>The Provider uses
                                its own blockchain security system to protect the data disclosed by the Customer
                                regarding to the Credential Cloud. However, the Customer may opt to use a third party
                                security system or elect to not use a security system at all.</p>

                            <p><span className='list-numbering'>8.2</span>If the Customer
                                decides to use a third party blockchain system, Provider may charge Customer additional
                                charges, and the Customer is entirely responsible for integrating the third party
                                system, assumes all risk of loss, damage, corruption, or disclosure of data, despite any
                                other warranties or obligations of Provider, and Customer agrees to indemnify, defend
                                and hold harmless Provider from and against any and all claims, liabilities, or losses
                                arising from the Customer's use or integration with the third party system.</p>
                        </div>
                        <div className="content-section" id="fees-and-payments">
                            <h2>9. Fees and Payments</h2>

                            <p>Customers with a paid subscription must provide a valid
                                credit card for payment of the applicable subscription
                                fees to the Provider. All subscription fees are exclusive of all federal, state,
                                provincial, municipal, or other taxes which Customers
                                agree to pay based on where the Customer is located. Invoices will include (1)
                                subscription fees and (2) all applicable sales taxes,
                                as amended from time to time, for the jurisdiction in which the Customer is located. In
                                the event of updated tax rates, the Provider
                                will apply the new tax rate without notice to the Customer. All payments made by the
                                Customers will be processed by a third party of
                                the Provider's choice (“Payment Processor”). The Provider will not store or collect
                                Customer's payment card details.
                                That information is provided directly to our third-party payment processors whose use of
                                your personal information is governed
                                by their Privacy Policy.</p>
                        </div>
                        <div className="content-section" id="limitation-of-liability">
                            <h2>10. Limitation of Liability</h2>

                            <p><span className='list-numbering'>10.1</span>Except in the
                                case of a violation by the Provider of its obligations under Section 5 above
                                (“Confidentiality”), Section 4 (“Customer Data”), and Section 12 (“Warranties”). The
                                Provider shall not be liable for, and Customer waives the right to claim any loss,
                                injury, claim, liability, or damage of any kind resulting in any way from the Services
                                provided to Customer by Provider.</p>

                            <p><span className='list-numbering'>10.2</span>CUSTOMER AGREES
                                THAT THE LIABILITY OF PROVIDER ARISING OUT OF ANY CLAIM IN ANY WAY CONNECTED WITH THE
                                SERVICE WILL NOT EXCEED THE TOTAL AMOUNT YOU HAD PAID FOR THE SERVICE PURSUANT TO THE
                                AGREEMENT WITHIN THE YEAR PERIOD BEFORE THE DATE OF THE CLAIM AROSE. THE CUSTOMER
                                FURTHER AGREES THAT PROVIDER IS NOT AND WILL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT,
                                INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER (INCLUDING WITHOUT
                                LIMITATION, ATTORNEY FEES) RELATING TO THIS AGREEMENT. THESE DISCLAIMERS APPLY
                                REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING WITHOUT
                                LIMITATION, ATTORNEY FEES) RELATING TO WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
                                STRICT LIABILITY OR OTHERWISE, WHETHER THOSE DAMAGES ARE FORESEEABLE, AND WHETHER THE
                                PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF THOSE DAMAGES.</p>
                        </div>
                        <div className="content-section" id="indemnification">
                            <h2>11. Indemnification</h2>

                            <p><span className='list-numbering'>11.1</span>Customer hereby
                                agrees to indemnify and hold harmless Provider from and against any claim, action,
                                proceeding, loss, liability, judgment, obligation, penalty, cost or expense, including
                                attorney's fees, which arise from or related to the following: (1) Customer's breach of
                                any obligation stated in this Agreement, and (2) Customer's negligent acts or omissions.
                                The Provider will provide prompt notice to the Customer of any indemnifiable event or
                                loss. Customer will undertake, at Customer's own cost, the defense of any claim, suit,
                                or proceeding with counsel reasonably acceptable to Provider. Provider reserves the
                                right to participate in defense of the claim, suit, or proceeding, at Provider's
                                expense, with counsel of Provider's choosing.</p>

                            <p><span className='list-numbering'>11.2</span>Provider shall
                                defend, indemnify and hold Customer harmless against any loss, damage, or costs
                                (including reasonable attorney's fees) in connection with claims, demands, suits, or
                                proceedings (“Claims”) made or brought against Customer by a third party alleging that
                                the Service, or use of the Service as contemplated hereunder, infringes a copyright, a
                                U.S. patent issued as of the date of final execution of this Agreement, or a trademark
                                of a third party or involves the misappropriation of any trade secret of a third party;
                                provided, however, that Customer (1) promptly gives written notice of the claim to
                                Provider, (2) gives Provider sole control of the defense and settlement of the claim,
                                and (3) provides to Provider, at Provider's cost, all reasonable assistance.
                                Notwithstanding this section, Provider shall not be liable to extent any infringement is
                                caused by Provider's combination of Services with third party hardware or software, or a
                                modification to Services.</p>
                        </div>
                        <div className="content-section" id="term-and-termination">
                            <h2>12. Term and Termination</h2>

                            <p>
                                This agreement shall come into force after Customer payment is processed by the
                                Provider. Either party may terminate this Agreement by providing the other party with at
                                least 30 days written notice of termination. The agreement will automatically be
                                terminated if the Customer does not renew the product or if payment cannot be processed.
                                Upon the termination of this Agreement, all of the provisions of this Agreement shall
                                cease to have effect, except the following provisions of this Agreement that will
                                survive termination in accordance with their express terms or otherwise
                                indefinitely: Section 4 (Customer Data), Sections 5 (Confidentiality), 10 (Limitation of liability), and 11 (Indemnification).
                            </p>
                        </div>
                        <div className="content-section" id="intellectual-property-rights">
                            <h2>13. Intellectual Property Rights</h2>

                            <p>
                                As between Customer and Provider, the Provider Intellectual Property is, and shall at
                                all times remain the sole and exclusive property of Provider. Provider shall have the
                                right, in its sole discretion, to modify the Provider Intellectual Property. “Provider
                                Intellectual Property” means (1) the Service; (2) all improvements, changes,
                                enhancements, and components thereof; (3) all other proprietary materials of Provider
                                and/or its licensors, and; (4) all other intellectual property owned by Provider
                                including, but not limited to, all copyrights, patents, trademarks and trade names,
                                trade secrets, specifications, methodologies, documentation, algorithms, criteria,
                                designs, report formats, and know how as well as any underlying source code and object
                                code related thereto.
                            </p>
                        </div>
                        <div className="content-section" id="miscellaneous">
                            <h2>14. Miscellaneous</h2>

                            <p><span className='list-numbering'>14.1</span>If a dispute
                                arises out of or relates to this Agreement, or the breach thereof, and if the dispute
                                cannot be settled through negotiation, the parties agree first to try in good faith to
                                settle the dispute by mediation administered by the American Arbitration Association
                                under its Commercial Mediation Procedures before resorting to arbitration, litigation,
                                or some other dispute resolution procedure. All disputes arising from or concerning the
                                Services or these terms, or their interpretation, violation, invalidity,
                                non-performance, or termination, will be submitted to final and binding arbitration
                                under the Rules of Arbitration of the American Arbitration Association applying
                                California law. The exclusive venue for any arbitration or court proceeding based on or
                                arising out of this Agreement shall be Santa Clara County, California.</p>

                            <p><span className='list-numbering'>14.2</span>This Agreement
                                and all claims or causes of action arising hereunder shall be governed by and construed
                                in accordance with the laws of the State of California, excluding its conflict of laws
                                provisions and the Parties (Provider and Customer) hereby submit to exclusive
                                jurisdiction in the federal or state courts located in Santa Clara County, California,
                                USA, and agree that venue is proper and convenient in such forums.</p>

                            <p><span className='list-numbering'>14.3</span>Any part,
                                provision, representation, or warranty of this Agreement which is prohibited or which is
                                held to be void or unenforceable shall be ineffective to the extent of such prohibition
                                or unenforceability without invalidating the remaining provisions hereof..</p>

                            <p><span className='list-numbering'>14.4</span>This Agreement
                                may be supplemented, amended, or modified only by the mutual agreement of the parties.
                                No supplement, amendment, or modification of this Agreement shall be binding unless it
                                is in writing and signed by both the Customer and the Provider.</p>

                            <p><span className='list-numbering'>14.5</span>Neither this
                                Agreement nor any of the rights, interests, or obligation hereunder shall be assigned by
                                the Customer without the Provider’s written consent. The Provider is entitled in this
                                Agreement to assign any of the rights, interests, or obligations hereunder without the
                                Customer's prior consent</p>
                        </div>
                        <div className="content-section" id="contact-us">
                            <h2>15. Contact Us</h2>

                            <p>
                                Hyperstack Credential Cloud™, Customer can contact Provider at URL
                                https://thehyperstack.com/contact-us/ or by email connect@thehyperstack.com.
                            </p>
                        </div>



                    </div>
                    <div className="content-nav">
                        <div className="nav-helper">
                            <a href="#terms-and-condition" className="nav-helper__link active-header">Subscription Agreement</a>
                            <a href="#services" className="nav-helper__link">Services</a>
                            <a href="#customer-responsibility" className="nav-helper__link">Customer Responsibility</a>
                            <a href="#customer-restrictions" className="nav-helper__link">Customer Restrictions</a>
                            <a href="#customer-data" className="nav-helper__link">Customer Data</a>
                            <a href="#confidentiality" className="nav-helper__link">Confidentiality</a>
                            <a href="#representations-and-warranties" className="nav-helper__link">Representations and Warranties</a>
                            <a href="#support-and-maintenance" className="nav-helper__link">Support and Maintenance</a>
                            <a href="#blockchain-security" className="nav-helper__link">Blockchain Security</a>
                            <a href="#fees-and-payments" className="nav-helper__link">Fees and Payments</a>
                            <a href="#limitation-of-liability" className="nav-helper__link">Limitation of Liability</a>
                            <a href="#indemnification" className="nav-helper__link">Indemnification</a>
                            <a href="#term-and-termination" className="nav-helper__link">Term and Termination</a>
                            <a href="#intellectual-property-rights" className="nav-helper__link">Intellectual Property Rights</a>
                            <a href="#miscellaneous" className="nav-helper__link">Miscellaneous</a>
                            <a href="#contact-us" className="nav-helper__link">Contact Us</a>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export const Head = () => <Seo title="Subscription Agreement of Hyperstack"
                               description="Subscription Agreement for Hyperstack Services"/>

export default SubscriptionAgreement
